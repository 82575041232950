import ApplicationController from './application_controller';
import { stringify } from 'querystring';
import { titleCase } from './helpers/utils';

export default class extends ApplicationController {
    static targets = [
        'editCard',
        'estimatedPlan',
        'viewCard',
        'form',
        'cardCancel',
        'planInput',
        'estimatedPlanInput',
        'estimatedAmount',
        'estimatedAnnualAmount',
        'planIdInput',
        'revenueOptions',
        'planSummary',
        'planName',
        'basePrice',
        'currentPlan',
        'submit',
        'nextPlan',
        'nextAnnualPlan',
        'proRataText',
        'selectionSummary',
        'annualSelectionSummary',
    ];

    static values = {
        period: {
            type: String,
            default: 'monthly',
        },
        discount: {
            type: Number,
            default: 0,
        },
    };

    connect() {
        if (this.hasRevenueOptionsTarget) {
            this.selectRevenueRange();
            this.disableRevenueOptions();
        }
        if (this.element.dataset.period) {
            this.periodValue = this.element.dataset.period;
        }
        this.discountValue = parseInt(this.element.dataset.discount);
        this.setPlanSummaryText();
    }

    disableRevenueOptions() {
        for (let option of this.revenueOptionsTarget.options) {
            if (parseFloat(option.value) < parseFloat(this.element.dataset.organizationRevenue)) {
                option.setAttribute('disabled', '');
            }
        }
    }

    discountChanged(event) {
        this.disableSaveButton();
        this.discountValue = event.target.value;
        this.setPlanSummaryText();
        this.enableSaveButton();
    }

    selectRevenueRange() {
        for (let option of this.revenueOptionsTarget.options) {
            if (parseFloat(option.value) >= parseFloat(this.element.dataset.organizationRevenue)) {
                option.setAttribute('selected', '');
                break;
            }
        }
    }

    estimateBilling() {
        if (
            this.element.dataset.selectedPeriod == 'annual' &&
            parseInt(this.revenueOptionsTarget.value) < parseInt(this.element.dataset.selectedRevenue) &&
            parseInt(this.element.dataset.creditBalance) != 0
        ) {
            return;
        }
        this.disableSaveButton();
        if (parseInt(this.revenueOptionsTarget.value) < parseInt(this.element.dataset.selectedRevenue))
        {
            this.estimateBillingPrice(this.element.dataset.selectedRevenue);
        }
        else {
            this.estimateBillingPrice(this.revenueOptionsTarget.value);
        }
    }

    estimateBillingPrice(revenue) {
        var context = this;
        this.ajax({
            url: this.element.dataset.url,
            type: 'POST',
            dataType: 'json',
            data: stringify({
                revenue: revenue,
                channels: this.element.dataset.channels,
                currency: this.element.dataset.currency,
                period: this.periodValue,
                current_plan_id: this.element.dataset.currentPlan,
            }),
            success: function (data) {
                if (parseInt(context.element.dataset.currentPlan) != data.plan_id) {
                    context.estimatedPlanInputTarget.value = data.plan_id;
                    context.estimatedPlanTarget.classList.remove('hidden');
                    const discountAmount = data.base_price_per_month * (1 - context.discountValue / 100);
                    if (context.element.dataset.selectedPeriod == 'monthly' && data.billing_period == 'annual') {
                        context.selectionSummaryTarget.classList.add('hidden');
                        context.annualSelectionSummaryTarget.classList.remove('hidden');
                        context.proRataTextTarget.classList.add('hidden');
                        context.nextAnnualPlanTarget.innerHTML = titleCase(data.plan_level);
                        context.estimatedAnnualAmountTarget.innerHTML = `${
                            context.element.dataset.currencySymbol
                        }${discountAmount.toFixed(2)}`;
                    } else {
                        const periodText = data.billing_period == 'monthly' ? 'monthly' : 'yearly';
                        context.nextPlanTarget.innerHTML = `${periodText} ${titleCase(data.plan_level)}`;
                        context.selectionSummaryTarget.classList.remove('hidden');
                        context.annualSelectionSummaryTarget.classList.add('hidden');
                        context.estimatedAmountTarget.innerHTML = `${
                            context.element.dataset.currencySymbol
                        }${discountAmount.toFixed(2)}`;
                        if (data.billing_period == 'annual') {
                            context.proRataTextTarget.classList.remove('hidden');
                        } else {
                            context.proRataTextTarget.classList.add('hidden');
                        }
                    }
                } else {
                    context.estimatedPlanInputTarget.value = null;
                    context.estimatedPlanTarget.classList.add('hidden');
                }
                context.enableSaveButton();
            },
        });
    }

    periodChange(event) {
        this.disableSaveButton();
        this.periodValue = event.target.value;
        if (parseInt(this.revenueOptionsTarget.value) < parseInt(this.element.dataset.selectedRevenue))
        {
            this.estimateBillingPrice(this.element.dataset.selectedRevenue);
        }
        else {
            this.estimateBillingPrice(this.revenueOptionsTarget.value);
        }
    }

    estimateBillingForShopify() {
        this.disableSaveButton();
        var context = this;
        this.ajax({
            url: this.element.dataset.url,
            type: 'POST',
            dataType: 'json',
            data: stringify({
                revenue: this.revenueOptionsTarget.value,
                channels: this.element.dataset.channels,
                currency: this.element.dataset.currency,
                period: this.periodValue,
                current_plan_id: this.element.dataset.currentPlan,
            }),
            success: function (data) {
                context.planNameTarget.innerHTML = data.plan_level;
                context.planIdInputTarget.value = data.id;
                context.planInputTarget.value = data.plan_id;
                context.basePriceTarget.innerHTML = `${context.element.dataset.currencySymbol}${data.base_price_per_month.toFixed(2)}`;
                context.enableSaveButton();
            },
        });
    }

    enableSaveButton() {
        this.sendEvent(this.formTarget, 'form:check-required-fields');
    }

    disableSaveButton() {
        this.submitTarget.setAttribute('disabled', '');
        this.submitTarget.classList.add('form-disabled');
    }

    setPlanSummaryText() {
        if (this.hasPlanSummaryTarget) {
            this.planSummaryTarget.classList.remove('hidden');
            let discount = this.discountValue;
            let period = this.element.dataset.selectedPeriod;
            let base_price = this.element.dataset.monthlyPrice;
            if (discount) {
                if (period == 'monthly') {
                    this.planSummaryTarget.innerHTML = `Includes a ${discount}% partner discount, regularly ${this.element.dataset.currency} ${this.element.dataset.currencySymbol}${base_price}.`;
                } else {
                    this.planSummaryTarget.innerHTML = `Includes 25% annual billed discount, and ${discount}% partner discount, regularly ${this.element.dataset.currency} ${this.element.dataset.currencySymbol}${base_price}.`;
                }
            } else {
                if (period == 'annual') {
                    this.planSummaryTarget.innerHTML =
                        'Includes 25% annual billed discount, regularly ' +
                        `${this.element.dataset.currency}` +
                        ` ${this.element.dataset.currencySymbol}${base_price}.`;
                } else {
                    this.planSummaryTarget.innerHTML = '';
                    this.planSummaryTarget.classList.add('hidden');
                }
            }
        }
    }

    addAttribute(element, attribute, value) {
        element.setAttribute(attribute, (element.getAttribute(attribute) || '') + ' ' + value);
    }

    replaceAttribute(element, attribute, oldVal, newVal) {
        element.setAttribute(attribute, (element.getAttribute(attribute) || '').replace(oldVal, newVal));
    }

    editCard(e) {
        e.preventDefault();
        this.formTarget.action = this.formTarget.action.replace('update_billing_details', 'store_payment_method');
        this.updateCard();
    }

    updateCard() {
        this.replaceAttribute(this.formTarget, 'data-action', 'form#submit', 'stripe#submit');
        this.formTarget.dataset.stripeLoadOnConnectValue = true;
        this.addAttribute(this.formTarget, 'data-controller', 'stripe');
        this.cardCancelTarget.classList.remove('!hidden');
        this.viewCardTarget.classList.add('hidden');
        this.editCardTarget.classList.remove('hidden');
    }

    viewCard() {
        this.cancelCard();
        this.formTarget.action = this.formTarget.action.replace('store_payment_method', 'update_billing_details');
    }

    cancelCard() {
        this.cardCancelTarget.classList.add('!hidden');
        this.viewCardTarget.classList.remove('hidden');
        this.editCardTarget.classList.add('hidden');
        this.sendEvent(this.formTarget, 'stripe:clear-card');
        this.replaceAttribute(this.formTarget, 'data-controller', 'stripe', '');
        this.replaceAttribute(this.formTarget, 'data-action', 'stripe#submit', 'form#submit');
    }
}
